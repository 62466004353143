import React from "react";
import { Link } from "react-router-dom";
import {
  ComponentLoader,
  ComponentHeader,
  Sidebar,
  Warn,
} from "../../components";
import { translate, toast, globalFunctions } from "../../lib";
import {
  licenseList,
  licenseInfo,
  licenseDelete,
  licenseActivate,
  licenseDeactivate,
} from "../../actions";
import $ from "jquery";
import Swal from "sweetalert2";

export class LicenseList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isScrollLoading: false,
      isContentLoading: false,

      count: 0,
      limit: 50,
      offset: 0,

      licenses: [],
      license: null,
      licenseID: null,
      licenseStatus: null,

      screenWidth: window.innerWidth,
    };

    $(window).resize(() => {
      this.setState({ screenWidth: $(window).innerWidth() });
    });

    globalFunctions.set({
      onLicenseDelete: (id) => {
        Swal.fire({
          title: translate.get("Verify"),
          text: translate.get("ConfirmIdsDelete"),
          icon: null,
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonClass: "custom-btn success-btn",
          cancelButtonClass: "custom-btn default-btn",
          confirmButtonText: translate.get("Confirm"),
          cancelButtonText: translate.get("Cancel"),
        }).then(async (res) => {
          if (res && res.value) {
            this.setState({ isContentLoading: true });
            let response = await licenseDelete({ id });
            if (response) {
              this.setState({ isContentLoading: false });
              toast.fire({
                icon: response.status === "success" ? "success" : "error",
                title: response.description,
              });
              if (response.status === "success") {
                this.setState({ license: null });
                this.reLoad();
              }
            }
          }
        });
      },
      changeLicenseStatus: async (status, id) => {
        this.setState({
          isLoading: true,
        });
        let response = null;
        if (status) {
          response = await licenseDeactivate({ id });
        } else {
          response = await licenseActivate({ id });
        }

        if (response) {
          this.setState({
            isLoading: false,
            licenseStatus: status ? 0 : 1,
            license: null,
          });
          toast.fire({
            icon: response.status === "success" ? "success" : "info",
            title: response.description,
            buttonsStyling: false,
            showConfirmButton: response.status !== "success",
            confirmButtonClass: "custom-btn default-btn",
            timer: response.status !== "success" ? null : 3000,
          });
          if (response.status === "success") {
            this.reLoad();
          }
        }
      },
    });
  }

  reLoad = async () => {
    this.setState({ isLoading: true });

    let { limit, offset } = this.state;
    let params = { limit, offset };
    let licenses = await licenseList(params);
    if (licenses) {
      this.setState({ isLoading: false });
      if (licenses.status === "success") {
        this.setState({ licenses: licenses.data, count: licenses.count });
      } else {
        this.setState({ licenses: [] });
        toast.fire({
          icon: "info",
          title: licenses.description,
          buttonsStyling: false,
          showConfirmButton: true,
          confirmButtonClass: "custom-btn default-btn",
          timer: null,
        });
      }
    }
  };

  componentDidMount() {
    this.reLoad();
  }

  onDelete = async (data) => {
    globalFunctions._onLicenseDelete(data.id);
  };

  onGet = async (data) => {
    if (data) {
      this.setState({ isContentLoading: true });
      let { seria, number, expire_date } = data;
      let date = expire_date.split("-");
      let params = {
        id: data.id,
        // series: seria + number,
        // expire_day: date[2],
        // expire_month: date[1],
        // expire_year: date[0]
      };

      let response = await licenseInfo(params);
      if (response) {
        this.setState({ isContentLoading: false });
        if (response.status === "success") {
          this.setState({
            license: response?.protocols || null,
            licenseID: data.id,
            licenseStatus: response?.data?.status || 0,
          });
          let { license, licenseID } = this.state;
          globalFunctions._setSidebarState({ license, licenseID });
        } else {
          this.setState({
            license: null,
            licenseID: null,
            licenseStatus: null,
          });
          globalFunctions._setSidebarState({
            license: null,
            licenseID: null,
            licenseStatus: null,
          });
          toast.fire({
            icon: "error",
            title: response.description,
            buttonsStyling: false,
            showConfirmButton: true,
            confirmButtonClass: "custom-btn default-btn",
            timer: null,
          });
        }
      }
    }
  };

  onScroll = async () => {
    let elmnt = document.querySelector(".sidebar__item-container");
    let scrollableHeight = elmnt.scrollHeight - elmnt.clientHeight;
    let percent = ((elmnt.scrollTop / scrollableHeight) * 100).toFixed();

    if (
      !this.state.isScrollLoading &&
      percent >= 100 &&
      this.state.offset + this.state.limit <= this.state.count
    ) {
      let skip = this.state.offset;
      await this.setState({ offset: (skip += 50) });

      this.setState({ isScrollLoading: true });
      let { limit, offset } = this.state;
      let params = { limit, offset };
      let response = await licenseList(params);
      if (response) {
        this.setState({ isScrollLoading: false });
        if (response.status === "success") {
          let licenses = this.state.licenses;
          this.setState({ licenses: [...licenses, ...response.data] });
        } else {
          toast.fire({
            icon: "error",
            title: response.description,
          });
        }
      }
    }
  };

  render() {
    let {
      isLoading,
      isScrollLoading,
      isContentLoading,
      licenses,
      license,
      licenseID,
      licenseStatus,
      screenWidth,
    } = this.state;

    return (
      <div className="content">
        <ComponentHeader
          title={translate.get("DriverIds")}
          back={"/"}
          msg={true}
          grid={true}
        />
        {isLoading ? (
          <ComponentLoader />
        ) : (
          <div className="License">
            <div className="license__content">
              <Sidebar
                data={licenses}
                type={"license"}
                onGet={this.onGet}
                onDelete={this.onDelete}
                onScroll={this.onScroll}
                isScrollLoading={isScrollLoading}
                isContentLoading={isContentLoading}
              />
              {screenWidth > 1024 ? (
                <div className="license__container">
                  <div className="license__content-main">
                    {isContentLoading ? (
                      <ComponentLoader />
                    ) : license ? (
                      <ContentLicense
                        canPay={true}
                        canDelete={true}
                        object={license}
                        objectID={licenseID}
                        objectStatus={licenseStatus}
                      />
                    ) : licenses.length === 0 ? (
                      <div className="d-flex flex-column align-items-center justify-content-center h-100 pt-5 mt-5">
                        <img src="/assets/images/licenses_info.svg" alt="" />
                        <p style={{ color: "#16A085", margin: "2em 0" }}>
                          {translate.get("DriverIdNone")}..
                        </p>
                        <Link to="/licenses/add">
                          <button className="custom-btn ">
                            {translate.get("AddDriverId")}
                          </button>
                        </Link>
                      </div>
                    ) : (
                      <div className="d-flex flex-column align-items-center justify-content-center h-100 pt-5 mt-5">
                        <img src="/assets/images/licenses_info.svg" alt="" />
                        <p style={{ color: "#16A085", margin: "1em 0" }}>
                          {translate.get("SelectDriverId")}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              ) : licenses.length === 0 ? (
                <div className="license__container">
                  <div className="license__content-main">
                    <div className="d-flex flex-column align-items-center justify-content-center h-100">
                      <img
                        src="/assets/images/licenses_info.svg"
                        alt=""
                        style={{
                          objectFit: "contain",
                          width: screenWidth < 399 ? "100%" : "",
                        }}
                      />
                      <p
                        style={{
                          color: "#16A085",
                          margin: "2em 0",
                          textAlign: "center",
                        }}
                      >
                        {translate.get("DriverIdNone")}..
                      </p>
                      <Link to="/cars/add">
                        <button className="custom-btn ">
                          {translate.get("AddDriverId")}
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export const ContentLicense = (props) => {
  let {
    object,
    objectID,
    objectStatus,
    // canDelete
  } = props;
  const [payInfo, setPayInfo] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  const onLoad = () => {
    setLoading(false);
  };

  return (
    <React.Fragment>
      {payInfo ? (
        <React.Fragment>
          {loading ? <ComponentLoader /> : null}
          {!loading && (
            <button
              className="btn shadow-none"
              onClick={() => {
                setPayInfo(null);
                setTimeout(() => {
                  setLoading(true);
                }, 300);
              }}
              style={{
                position: "absolute",
                right: 10,
                fontSize: 20,
                color: "wheat",
              }}
            >
              <i className="feather feather-x" />
            </button>
          )}
          <iframe
            src={`https://api.az/${payInfo}`}
            width="100%"
            height="500"
            allowFullScreen
            onLoad={onLoad}
            style={{ border: "none", borderRadius: 7 }}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          {object?.status === "error" ? (
            <Warn desc={object.description} br={"#F3EBCF"} warn={true} />
          ) : (
            <>
              <div className="custom-row">
                <div className="custom-row__data">
                  <p className="data-title">
                    {translate.get("ProtocolScore")}:
                  </p>
                  <p className="data-content">{object.point}</p>
                </div>
              </div>

              {object.point_protocols.length > 0 &&
                object.point_protocols.map((row, i) => (
                  <div className="custom-row" key={i}>
                    {row.point ? (
                      <div className="custom-row__data">
                        <p className="data-title">
                          {translate.get("ProtocolScore")}:
                        </p>
                        <p className="data-content">{row.point}</p>
                      </div>
                    ) : null}

                    {row.protocol_number ? (
                      <div className="custom-row__data">
                        <p className="data-title">
                          {translate.get("Protocol")}:
                        </p>
                        <p className="data-content">{row.protocol_number}</p>
                      </div>
                    ) : null}

                    {row.car_number ? (
                      <div className="custom-row__data">
                        <p className="data-title">
                          {translate.get("CarrNumber")}:
                        </p>
                        <p className="data-content">{row.car_number}</p>
                      </div>
                    ) : null}

                    {row.decision_date ? (
                      <div className="custom-row__data">
                        <p className="data-title">
                          {translate.get("DataWritting")}:
                        </p>
                        <p className="data-content">{row.decision_date}</p>
                      </div>
                    ) : null}

                    {row.expire_date ? (
                      <div className="custom-row__data">
                        <p className="data-title">
                          {translate.get("ExpireDate")}:
                        </p>
                        <p className="data-content">{row.expiry_date}</p>
                      </div>
                    ) : null}

                    <div className="custom-row__data">
                      <p className="data-title">
                        {row.can_pay && (
                          <Link
                            target="blank"
                            to={{
                              pathname: `https://api.az/${row.protocol_number}`,
                            }}
                            className="custom-btn min br w-50"
                          >
                            {translate.get("PayOnline")}
                          </Link>
                        )}
                      </p>
                    </div>
                  </div>
                ))}

              {object.unpaid_protocols.length > 0 ? (
                <>
                  <p className="custom-row-header">
                    {translate.get("UnpaidFines")}
                  </p>
                  {object.unpaid_protocols.map((row, i) => (
                    <div className="custom-row" key={i}>
                      {row.protocol_number ? (
                        <div className="custom-row__data">
                          <p className="data-title">
                            {translate.get("Protocol")}:
                          </p>
                          <p className="data-content">{row.protocol_number}</p>
                        </div>
                      ) : null}

                      {row.car_number ? (
                        <div className="custom-row__data">
                          <p className="data-title">
                            {translate.get("CarrNumber")}:
                          </p>
                          <p className="data-content">{row.car_number}</p>
                        </div>
                      ) : null}

                      {row.date ? (
                        <div className="custom-row__data">
                          <p className="data-title">
                            {translate.get("DataWritting")}:
                          </p>
                          <p className="data-content">{row.date}</p>
                        </div>
                      ) : null}

                      {row.amount ? (
                        <div className="custom-row__data">
                          <p className="data-title">{translate.get("Fine")}:</p>
                          <p className="data-content">{`${row.amount} AZN`}</p>
                        </div>
                      ) : null}

                      {row.discount ? (
                        <div className="custom-row__data">
                          <p className="data-title">
                            {translate.get("Discount")}:
                          </p>
                          <p className="data-content">{`${row.discount} AZN`}</p>
                        </div>
                      ) : null}

                      {row.total ? (
                        <div className="custom-row__data">
                          <p className="data-title">
                            {translate.get("FinalAmount")}:
                          </p>
                          <p className="data-content">{`${row.total} AZN`}</p>
                        </div>
                      ) : null}

                      <div className="custom-row__data">
                        <p className="data-title">{translate.get("Status")}:</p>
                        <p className="data-content">{row.status.text}</p>
                      </div>

                      <div className="custom-row__data">
                        <p className="data-title">
                          {props.canPay && row.pay && (
                            <button
                              className="custom-btn min br w-50"
                              onClick={() =>
                                window.open(
                                  `https://api.az/${row.protocol_number}`
                                )
                              }
                            >
                              {translate.get("PayOnline")}
                            </button>
                          )}
                        </p>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div className="custom-row">
                  <p className="custom-row-header">
                    {translate.get("NoProtocolOfDriverId")}
                  </p>
                </div>
              )}

              <div className="custom-row">
                <div className="custom-row__data">
                  <p className="data-title">{translate.get("Deprivation")}:</p>
                  <p className="data-content">
                    {translate.get(object.restriction ? "Exist" : "NoExist")}
                  </p>
                </div>
              </div>
            </>
          )}

          <div className="row text-center">
            <div className="col-md-12 p-md-3">
              <button
                onClick={() =>
                  globalFunctions._changeLicenseStatus(objectStatus, objectID)
                }
                className={`custom-btn ${
                  objectStatus ? "danger" : "success"
                }-btn br m-2`}
              >
                {translate.get(`${objectStatus !== 0 ? "DeActive" : "Active"}`)}
              </button>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
